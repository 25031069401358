import React, { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../css files/printbill.css'
import ReactToPrint from 'react-to-print';
import { Button } from '@mui/material';

const PrintBill = () => {
    const location = useLocation();
    const printingbill = location.state ? location.state.printingbill : [];
    const tableRef=useRef(null)
     const history=useNavigate()
    // Calculate total amount
    let totalAmount = 0;
    printingbill.forEach(item => {
        totalAmount += item.amount;
    });
   
    const backtobillpage=()=>{
      history('/createinvoice')
    }
    return (
        <>
        <ReactToPrint
           trigger={()=><Button  variant='contained' color='error' sx={{display:'flex',position:'absolute',bottom:'100px',right:'50px'}}  onClick={backtobillpage}>Print</Button>}
           content={()=>tableRef.current}
        />
        <div ref={tableRef}>
            <div className="print-container" >
                <div className="shop-details">
                   <h3>Shop Name</h3>
                    <strong>Address</strong>
                </div>
                <table className='print-table'>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Enterprise</th>
                            <th>BuyerName</th>
                            {/* <th>Place</th> */}
                            <th>Quantity</th>
                            <th>Commodity</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {printingbill.map((dat, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{dat.enterprise}</td>
                                <td>{dat.sellername}</td>
                                {/* <td>{dat.place}</td> */}
                                <td>{dat.quantity}</td>
                                <td>{dat.commodity}</td>
                                <td>{dat.amount}</td>
                            </tr>
                        ))}
                        <tr className="total-row">
                            <td colSpan="5">Total amount:</td>
                            <td>{totalAmount}</td>
                        </tr>
                        
                    </tbody>
                </table>
                {/* <div className='print-total'>
                            <h4 colSpan="5">Total amount:</h4>
                            <h3 >{totalAmount}</h3>
                </div> */}
            </div>
            <div className='bank-details'>
                    <strong>BankName:Indian Bank</strong>
                    <strong>BranchName:Virudhunagar</strong>
                    <strong>AccountNumber:203803030</strong>
                    <strong>IFSCcode:IAB283473493</strong>
            </div>

        </div>
        <div className="">
            <Button variant='contained' color='primary' sx={{display:'flex',position:'absolute',right:'50px'}} onClick={backtobillpage} >Back</Button>
        </div>
        </>
    );
};

export default PrintBill;
