// App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import CreateInvoice from './Pages/CreateInvoice';
import Createcustomer from './Pages/Createcustomer';
import Viewcustomer from './Pages/Viewcustomer';
import Usercreation from './Pages/Usercreation';
import Editcustomer from './Pages/Editcustomer';

import Reportgeneration from './Pages/Reportgeneration';
import Backup from './Pages/Backup';
import "bootstrap/dist/css/bootstrap.min.css";
import Viewbill from './Pages/Viewbill';
import Customerbilldetails from './Pages/Customerbilldetails';
import Edituser from './Pages/Edituser';
import PrintBill from './Pages/PrintBill';
import SideBar from './Components/Sidebar';
import Unauthorizedpage from '../src/Pages/Unauthorizedpage'
import axios from './api/axios';
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [printingbill, setPrintingbill] = useState([]);
  const [data, setData] = useState([]);
  const [role, setRole] = useState('Administrator');
  const [rolelocal,setRolelocal]=useState(localStorage.getItem('role') || '')
  const[usernameforsidebar,setUsernameforsidebar]=useState()
 
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
    const storedRole = localStorage.getItem('role');
  if (storedRole) {
    setRole(storedRole);
  }
    setUsernameforsidebar(localStorage.getItem('role') || null)
    fetchUserRole()
  }, []);


  const fetchUserRole = () => {
    setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
  const username = localStorage.getItem('username');
    if (username) {
      axios.get(`http://localhost:3001/api/usercreation/${username}`)
        .then((res) => {
          const fetchedRole = res.data[0]?.Role;
          if (fetchedRole && fetchedRole !== 'Employee') { // Check if fetched role is not 'Employee'
            setRole(fetchedRole);
          }
          setUsernameforsidebar(role);
        })
        .catch((err) => {
          console.log('Error fetching user role:', err);
        });
    }
   };

useEffect(()=>{
   if(role===null){
    setRole('Employee')
   }
},[role])

  return (
    
    <BrowserRouter>
      
        <Routes>
          <>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} setRole={setRole} setUsernameforsidebar={setUsernameforsidebar} />} />
          {isLoggedIn ? (
            <>
            {role==='Administrator' && (
              <>
              <Route  path='/dashboard' element={<Dashboard usernameforsidebar={usernameforsidebar}/>}/>
                  <Route path='/createinvoice' element={<CreateInvoice setPrintingbill={setPrintingbill} usernameforsidebar={usernameforsidebar} printingbill={printingbill} />} />
                  <Route path='/viewbill' element={<Viewbill usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/printbill' element={<PrintBill printingbill={printingbill} />} />
                  <Route path='/createcustomer' element={<Createcustomer usernameforsidebar={usernameforsidebar}  />} />
                  <Route path='/viewcustomer' element={<Viewcustomer usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/customerbilldetails/:CustomerID' element={<Customerbilldetails usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/editcustomer/:CustomerID' element={<Editcustomer usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/usercreation' element={<Usercreation usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/edituser/:ID' element={<Edituser usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/reportgeneration' element={<Reportgeneration usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/backup' element={<Backup usernameforsidebar={usernameforsidebar} />} />
              </>
            )}
                 
                  {role==='Employee' &&(
                    <>
                    <Route  path='/dashboard' element={<Dashboard usernameforsidebar={usernameforsidebar}/>}/>
                  <Route path='/createinvoice' element={<CreateInvoice setPrintingbill={setPrintingbill} usernameforsidebar={usernameforsidebar} printingbill={printingbill} />} />
                  <Route path='/viewbill' element={<Viewbill usernameforsidebar={usernameforsidebar} />} />
                  <Route path='/printbill' element={<PrintBill usernameforsidebar={usernameforsidebar} printingbill={printingbill} />} />
                  <Route path='/createcustomer' element={<Createcustomer usernameforsidebar={usernameforsidebar}  />} />

                  <Route path='/*' element={<Unauthorizedpage/>}/>
                    </>
                  )}

                  
                  </>
          ) : null}
                </>
            
        </Routes>
      
    </BrowserRouter>
    
  );
}

export default App;
