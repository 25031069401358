import React from 'react'
import { Link } from 'react-router-dom'

const Unauthorizedpage = () => {
  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <h1>Unauthorized Access</h1>
      <p>You are not authorized to view this page.</p>
      <Link to={"/dashboard"} className='btn btn-success '>Back</Link>

    </div>
  )
}

export default Unauthorizedpage