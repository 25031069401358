import React from 'react'
import SideBar from '../Components/Sidebar'
import { Box, Button, Container, TextField } from '@mui/material'
const Backup = ({usernameforsidebar}) => {
  return (
    <>
      <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
          <SideBar usernameforsidebar={usernameforsidebar} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 ,marginTop:"55px"}}>
          <h1>BackUp</h1>
          </Box>
        </Box>
     </>
  )
}

export default Backup