import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../css files/invoicedetails.css';
import { FaSearchengin } from 'react-icons/fa6';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Viewcustomer = ({usernameforsidebar}) => {
    //STATES
    const [data, setData] = useState([]);
    const [record, setRecord] = useState([]);
    const [currentpage, setCurrentpage] = useState(1);
    const recordsperpage = 8;
    const lastIndex = currentpage * recordsperpage;
    const firstIndex = lastIndex - recordsperpage;
    const content = record.slice(firstIndex, lastIndex);
    const npage = Math.ceil(record.length / recordsperpage);
    const numbers = [...Array(npage).keys()].map((i) => i + 1); // Corrected the generation of numbers array
    const history = useNavigate();

    //HOOKS
    useEffect(() => {
        axios.get('http://localhost:3001/api/customers')
            .then((res) => {
                setData(res.data);
                setRecord(res.data);
            })
            .catch((err) => console.log("there is an error", err));
    }, []);

    const handlefilter = (e) => {
        setRecord(data.filter((fil) => fil.CustomerName.toLowerCase().includes(e.target.value)));
    };

    //Pagination Functions
    const prepage = () => {
        if (currentpage > 1) {
            setCurrentpage(currentpage - 1);
        }
    };

    const nextpage = () => {
        if (currentpage < npage) {
            setCurrentpage(currentpage + 1);
        }
    };

    const changeCpage = (page) => {
        setCurrentpage(page);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar usernameforsidebar={usernameforsidebar} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
                <div className="d-flex flex-column align-items-center bg-light vh-100">
                    <h1>List of Customers</h1>
                    <div className="w-95 rounded bg-white border shadow p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <input type="text" placeholder='Search' onChange={handlefilter} />
                            <Link to={"/createcustomer"} className='btn btn-success '>Add customer</Link>
                        </div>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>CustomerID</th>
                                    <th>Customer Name</th>
                                    <th>Mobile Number</th>
                                    <th>PinCode</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    content.map((dat, index) => (
                                        <tr key={index}>
                                            <td>{dat.CustomerID}</td>
                                            <td>{dat.CustomerName}</td>
                                            <td>{dat.Mobilenumber}</td>
                                            <td>{dat.Pincode}</td>
                                            <td>
                                            <Link to={`/customerbilldetails/${dat.CustomerID}`} className='btn btn-sm btn-primary me-2'>View</Link>
                                                <Link to={`/editcustomer/${dat.CustomerID}`} className='btn btn-sm btn-info me-2'>Edit</Link>
                                                {/* <Link to={`/editcustomer/${dat.CustomerID}`} className='btn btn-sm btn-danger me-2'>Delete</Link> */}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <nav>
                            <ul className='pagination'>
                                <li className='page-item'>
                                    <button onClick={prepage} className='page-link'>Prev</button>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item${currentpage === n ? ' active' : ''}`} key={i}>
                                            <button onClick={() => changeCpage(n)} className='page-link'>
                                                {n}
                                            </button>
                                        </li>
                                    ))
                                }
                                <li className='page-item'>
                                    <button onClick={nextpage} className='page-link'>Next</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default Viewcustomer;
