import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import Box from '@mui/material/Box';
import { Button, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import axios from 'axios';

const Createcustomer = ({role,usernameforsidebar}) => {
    const [addnewcustomers, setAddnewcustomers] = useState({
        customername: '',
        mobilenumber: '',
        address: '',
        state:'',
        town:'',
        pincode: '',
        
    });
    const navigate = useNavigate();
    const[dataget,setDataget]=useState([])
   

    const handleaddnewcustomers = (e) => {
        const { name, value } = e.target;
        setAddnewcustomers({ ...addnewcustomers, [name]: value });
    };

    const handleallcustomers = (e) => {
        e.preventDefault();

        if (!addnewcustomers.customername && !addnewcustomers.mobilenumber) {
            alert("Enter Customer name, Mobile number");
            return;
        }
        
          
        const data = addnewcustomers;
        axios.post('http://localhost:3001/api/customers', data)
            .then((res) => {
                console.log(res);
                alert("Customer Created Successfully");
                setAddnewcustomers({
                    customername: '',
                    mobilenumber: '',
                    address: '',
                    state:'',
                    town:'',
                    pincode: '',
                    
                });
                navigate('/viewcustomer');
            })
            .catch((err) => {
                console.log("There is an error", err);
            });
        setAddnewcustomers({
            customername: '',
            mobilenumber: '',
            address: '',
            state:'',
            town:'',
            pincode: '',
        });
    };
    useEffect(()=>{
         console.log('rolecustomer:',role)
    },[])
    // useEffect(() => {
    //     axios.get('http://localhost:3001/api/customers')
    //         .then((res) => {
    //             setDataget(res.data);
    //             // console.log(dataget.map((d,i)=>d.CustomerName))
    //              console.log(dataget)
                 
    //         })
    //         .catch((err) => console.log("there is an error", err));
    // }, []);
    
    return (
        <>
       
        <Box sx={{ display: 'flex' }}>
            <SideBar usernameforsidebar={usernameforsidebar} />
            
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
                <Container>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <h2 style={{ color: 'dodgerblue' }}>Add New Customer</h2>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Customer Name" variant="filled" fullWidth required name='customername' value={addnewcustomers.customername} onChange={handleaddnewcustomers} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Mobile Number" variant="filled" fullWidth required name='mobilenumber' value={addnewcustomers.mobilenumber} onChange={handleaddnewcustomers} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Address" variant="filled" fullWidth name='address' value={addnewcustomers.address} onChange={handleaddnewcustomers} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="State" variant="filled" fullWidth name='state' value={addnewcustomers.state} onChange={handleaddnewcustomers} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Town" variant="filled" fullWidth name='town' value={addnewcustomers.town} onChange={handleaddnewcustomers} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="PinCode" variant="filled" fullWidth name='pincode' value={addnewcustomers.pincode} onChange={handleaddnewcustomers} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Button variant='contained' color='secondary' sx={{  justifySelf: 'center', ':hover': { backgroundColor: 'green' } }} onClick={handleallcustomers}>Add Customer</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
             
        </Box>
       
        </>
    );
}

export default Createcustomer;
