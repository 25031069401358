import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SideBar from '../Components/Sidebar'
import axios from 'axios'
import { TextField,Button } from '@mui/material'


const Edituser = ({usernameforsidebar}) => {
const [data,setData]=useState({
    UserName:'',
    Password:'',
    Role:''
})
const {ID}=useParams()
const history=useNavigate()


useEffect(()=>{
    axios.get(`http://localhost:3001/api/usercreation/${ID}`)
    .then((res)=>{
             if(res.data&&res.data.length>0){
                setData(res.data[0])
                console.log('users data:',data)
             }
             else{
                console.log("no users data received")
             }
    })
     .catch((err)=>{
         console.log("Error occured while getting user for edit",err)
     })


},[])
const handleupdate=(e)=>{
    e.preventDefault();
    axios.put(`http://localhost:3001/api/usercreation/${ID}`,data)
    .then((res)=>{
          console.log("customer update data:",res)
     
    })
    .catch((err)=>{
         console.log("Error occured while updating customerdata",err)
    })
    history('/usercreation')
  }

    




  return (
    <>
         <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <SideBar usernameforsidebar={usernameforsidebar} />
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
          <h1>Update Details of Customer</h1>
          <div className="d-flex w-100 vh-60 justify-content-center align-items-center bg-light">
            <div className="w-90 border bg-white shadow px-5 pt-3 pb-5 rounded">
              <form style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px',width:'100%' }} onSubmit={handleupdate}>
                <TextField
                 label="UserName"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.UserName}
                  onChange={(e)=>setData({...data,UserName:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.Password}
                  onChange={(e)=>setData({...data,Password:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                <TextField
                  label="Role"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.Role}
                  onChange={(e)=>setData({...data,Role:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
              </form>
              <Container sx={{display:'flex',justifyContent:'space-between',marginTop:'30px'}}>
              <Button variant="contained" color="primary" type="submit" onClick={handleupdate}>
                  Update
                </Button>
                <Link to='/usercreation' style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="secondary" >
                    Cancel
                  </Button>
                  </Link>
              </Container>
              
            </div>
          </div>
        </Box>
      </Box>
    
    </>
  )
}

export default Edituser