import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import { Box, Button, Container, TextField } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Editcustomer = ({usernameforsidebar}) => {
  // STATES
  const { CustomerID } = useParams();
  const [data, setData] = useState({
    customerId: '',
    CustomerName: '',
    Mobilenumber: '',
    Address: '',
    State: '',
    Town: '',
    Pincode: ''
  });
     const history=useNavigate()
  // HOOKS
  useEffect(() => {
    axios.get(`http://localhost:3001/api/customers/${CustomerID}`)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setData(res.data[0]); // Access the first element of the array
          
        } else {
          console.log("No data received from the server");
        }
      })
      .catch((err) => console.log("there is an error", err));


  }, []);
  // useEffect(()=>{
  //   console.log("customerdata124:", data);
  //  },[])
       // FUNCTION
       const handleupdate=(e)=>{
         e.preventDefault();
         axios.put(`http://localhost:3001/api/customers/${CustomerID}`,data)
         .then((res)=>{
               console.log("customer update data:",res)
          
         })
         .catch((err)=>{
              console.log("Error occured while updating customerdata",err)
         })
         history('/viewcustomer')
       }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <SideBar usernameforsidebar={usernameforsidebar} />
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
          <h1>Update Details of Customer</h1>
          <div className="d-flex w-100 vh-60 justify-content-center align-items-center bg-light">
            <div className="w-90 border bg-white shadow px-5 pt-3 pb-5 rounded">
              <form style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px',width:'100%' }} onSubmit={handleupdate}>
                {/* <TextField
                 label="CustomerID"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.CustomerID}
                  onChange={(e)=>setData({...data,customerId:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                /> */}
                <TextField
                  label="Customer Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.CustomerName}
                  onChange={(e)=>setData({...data,CustomerName:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                  disabled={false} 
                />
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.Mobilenumber}
                  onChange={(e)=>setData({...data,Mobilenumber:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.Address}
                  onChange={(e)=>setData({...data,Address:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.State}
                  onChange={(e)=>setData({...data,State:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                <TextField
                  label="Town"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.Town}
                  onChange={(e)=>setData({...data,Town:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                <TextField
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={data.Pincode}
                  onChange={(e)=>setData({...data,Pincode:e.target.value})}
                  InputLabelProps={{ shrink: true }} 
                />
                
              </form>
              <Container sx={{display:'flex',justifyContent:'space-between',marginTop:'30px'}}>
             
              <Button variant="contained" color="primary" component={Link} to={'/viewcustomer'} type="submit" onClick={handleupdate} >
                  Update
                </Button>
                
                <Link to='/viewcustomer' style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="secondary" >
                    Back
                  </Button>
                  </Link>
              </Container>
              
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default Editcustomer;
