import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import Box from '@mui/material/Box';
import { Button, Container, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import axios from 'axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';



const CreateInvoice = ({usernameforsidebar}) => {
    const [items, setItems] = useState([]);     // THIS STATE HOLD  ALL BILLS TO POST
    const [addnewitems, setAddnewitems] = useState({
        quantity: '',
        commodity: '',
        rate: ''
    });
    const [selectdate, setSelectdate] = useState(new Date());
    const [value, setValue] = useState('');   // THIS FOR THE DATE PICKER
    const [totalamount,setTotalamount]=useState(0)
    const[sellername,setSellername]=useState('')
    const[place,setPlace]=useState('')
    const [amount,setAmount]=useState(0)
    const navigate = useNavigate();
    const [customerdata,setCustomerdata]=useState([]) // TO GET CUSTOMER DATAS
    const [customerId,setCustomerId]=useState(0)     // TO GET THE CUSTOMER ID
    const[pendingdata,SetPendingdata]=useState([])  // TO GET DATA FROM PENDING TABLE
    const [printingbill,setPrintingbill]=useState([])  // TO PRINT THE BILL
    const handleaddnewitems = (e) => {
        const { name, value } = e.target;
        setAddnewitems({ ...addnewitems, [name]: value });
    }

    const handledatechange = (date) => {
        setSelectdate(date);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    /// FOR ADD BUTTON TO ADD QUANTITY ETC...
    const handlemultipleitems=()=>{
        const amount = parseFloat(addnewitems.quantity) * parseFloat(addnewitems.rate);
        const data = {
            date: selectdate.toLocaleString(),
            enterprise: value,
            sellername:sellername,
             place:place,
             amount:amount,
             customerId:customerId,
             totalamount:totalamount,
            ...addnewitems
        };
        
        
        setItems(prevItems => {
            const updatedItems = [...prevItems, data];
            console.log("Updated Items:", updatedItems)
            handletotalamount(updatedItems)
            setPrintingbill([...updatedItems])
            return updatedItems;
        });
        console.log(items)
        setAddnewitems({
            quantity: '',
            commodity: '',
            rate: ''
        });
        setSelectdate(new Date());
    }
    const handletotalamount = (updatedItems) => {
        let total = 0;
        updatedItems.forEach(item => {
            total += parseFloat(item.amount);
        });
        console.log("Total Amount:", total); // Add this line for debugging
        setTotalamount(total);
    }
    // FOR THE CREATE INVOICE BUTTON
    const handleallitems = (e) => {
        e.preventDefault();
        if (!sellername && !place && !value ) {
            alert("Enter Enterprise,Seller name and Place");
            return;
        }
        setItems([...items,{totalamount}])
        axios.post("http://localhost:3001/api/bills", items)
            .then((res) => {
                console.log(res);
                alert("Bill Created Successfully");
               
                navigate('/printbill', { state: { printingbill: items } });
                handletotalamount()
                
            })
            .catch((err) => {
                console.log("there is an error", err);
            });
            setAddnewitems({
                quantity: '',
                commodity: '',
                rate: ''
            });
            setSelectdate(new Date());
            setSellername('')
            setPlace('')
         console.log('printbill:',printingbill)
          
            
    };
   
    /// FOR ADD TO DRAFT BUTTON

    const handlestatustable = (e) => {
        e.preventDefault();
        if (!sellername && !place) {
            alert("Enter Seller name and Place");
            return;
        }
        setItems([...items,{totalamount}])
        setTotalamount(0)
        axios.post("http://localhost:3001/api/pendinginvoices", { bills: items })
    .then((res) => {
        console.log(res);
        alert("Bills added to pending Successfully");
        // Clear items array after successful creation
        setItems([]);
        // Optionally, you can also reset other state variables like sellername, place, etc.
        
    })
    .catch((err) => {
        console.log("There was an error creating bills:", err);
        alert("Error creating bills. Please try again.");
    });

            setAddnewitems({
                
                quantity: '',
                commodity: '',
                rate: ''
            });
            setSellername('')
            setPlace('')
            setSelectdate(new Date());
            console.log('postitems:',items)
            
    };
    
    // TO GET THE CUSTOMER DETAILS
    useEffect(()=>{
        axios.get(`http://localhost:3001/api/customers`)
        .then((res)=>{setCustomerdata(res.data)
       console.log('Customerdatainiinvoice',customerdata)})
        .catch((err)=>console.log("there is an error",err))
    },[])
    // GET THE PENDING BILLS
    useEffect(() => {
        if (customerId !== 0) {
            axios.get(`http://localhost:3001/api/pendinginvoices/${customerId}`)
                .then((res) => {
                    SetPendingdata(res.data);
                    console.log('pendingdata:', res.data);
                })
                .catch((err) => console.log("there is an error", err));
        }
    }, [customerId]);
    // HANDLE PENDING TO INVOICE ONCLICK
    const pendingtoinvoice = (index, InvoiceID) => {
        const clickedbill = pendingdata[index];
        const confirmDelete = window.confirm('Are you sure you want to add this pending bill to items?');
    
        if (confirmDelete) {
            axios.delete(`http://localhost:3001/api/pendinginvoices/${clickedbill.InvoiceID}`)
                .then((res) => {
                    console.log("deleted pending bills", res.data);
                    const updatedPendingdata = [...pendingdata];
                    updatedPendingdata.splice(index, 1); // Remove the deleted pending bill from the array
                    SetPendingdata(updatedPendingdata); // Update the state with the modified array
                    console.log('updatedpendingdata:', updatedPendingdata);
    
                    const pendingAmount = parseFloat(clickedbill.Amount);
                    setItems(prevItems => [
                        ...prevItems,
                        {
                            quantity: clickedbill.Quantity,
                            commodity: clickedbill.Commodity,
                            rate: clickedbill.Rate,
                            date: clickedbill.Date,
                            enterprise: clickedbill.Enterprise,
                            sellername: clickedbill.SellerName,
                            place: clickedbill.place,
                            amount: pendingAmount,
                            customerId: clickedbill.CustomerID,
                            InvoiceID: InvoiceID
                        }
                    ]);
    
                    const newTotalamount = totalamount + pendingAmount;
                    setTotalamount(newTotalamount);
                })
                .catch((err) => {
                    console.log("error deleting pending data", err);
                });
        }
    };
    
    
    const PrintBill=()=>{
        if (printingbill) {
            navigate('/printbill', { state: { printingbill } });
        } else {
            console.error("Printing bill state is null or undefined");
        }
        setPrintingbill([])
    }
    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <SideBar usernameforsidebar={usernameforsidebar} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
                <Container sx={{ display: 'flex',flexDirection:'column', gap: '20px',width:'70%',marginRight:'380px'}}>
                    <Typography variant="h4" sx={{ gridColumn: '1 / span 2', textAlign: 'center' }}>Brokerage Billing</Typography>
                    <DatePicker
                        label="Date"
                        inputFormat="MM/dd/yyyy"
                        value={selectdate}
                        onChange={handledatechange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <Select
                        value={value}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select' }}
                    >
                        <MenuItem value="" disabled>
                            Enterprise
                        </MenuItem>
                        <MenuItem value={'EnterpriseA'}>Enterprise A</MenuItem>
                        <MenuItem value={'EnterpriseB'}>Enterprise B</MenuItem>
                        <MenuItem value={'EnterpriseC'}>Enterprise C</MenuItem>
                    </Select>

                    <Select
    value={sellername}
    onChange={(e) => {
        const selectedCustomer = customerdata.find(customer => customer.CustomerName === e.target.value);
        if (selectedCustomer) {
            setSellername(selectedCustomer.CustomerName);
            setCustomerId(selectedCustomer.CustomerID); // Assuming you have a state for customer ID
        }
    }}
    displayEmpty
    inputProps={{ 'aria-label': 'Select Seller Name' }}
>
    <MenuItem value="" disabled>
        Select Seller Name
    </MenuItem>
    {customerdata.map((customer, index) => (
        <MenuItem key={index} value={customer.CustomerName}>
            {customer.CustomerName}
        </MenuItem>
    ))}
</Select>


                    <TextField id="standard-basic" label="Place" variant="standard" className='text-input' required name='place' value={place} onChange={(e)=>setPlace(e.target.value)} />
                    <div className="table">
                    <table className='table table-striped'>
                        <thead>
                          <tr>
                             <th>Quantity</th> 
                            <th>Commodity</th>
                            <th>Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                           
                              <tr>
                                    {/* <td>{dat.CustomerID}</td> */}
                                   
                                     <td><TextField id="standard-basic" label="Quantity" variant="standard" type='number' className='text-input' name='quantity' value={addnewitems.quantity} onChange={handleaddnewitems} /></td> 
                                    <td> <TextField id="standard-basic" label="Commodity" variant="standard" className='text-input' name='commodity' value={addnewitems.commodity} onChange={handleaddnewitems} />
    </td>
                                    <td><TextField id="standard-basic" label="Rate" variant="standard" className='text-input' name='rate' value={addnewitems.rate} onChange={handleaddnewitems} />
   </td>
                                    <td><div><Button variant='contained' color='success' sx={{ gridColumn: '1', justifySelf: 'center', ':hover': { backgroundColor: 'red' }  }} onClick={handlemultipleitems}>Add</Button></div></td>
                                    <td></td>
                              </tr>
                        </tbody>
                      </table>
                      
                    </div>
                    
                </Container>
                <Container sx={{display:'flex',flexDirection:'column',position:'absolute',top:'100px',left:'960px',marginTop:'100px',width:'20%',gap:'20px'}}>
                <Button variant='contained' color='primary' sx={{  justifySelf: 'center', ':hover': { backgroundColor: 'green' } }} onClick={handleallitems}>Create Invoice</Button>
                    <Button variant='contained' color='secondary' sx={{  justifySelf: 'center', ':hover': { backgroundColor: 'green' } }} onClick={handlestatustable}>Add To Draft</Button>
                    
                </Container>   
                <Container>
                <div className="table">
                    <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Serial Number</th> 
                            <th>Enterprise</th>
                            <th>Seller Name</th>
                            <th>Place</th>
                             <th>Quantity</th> 
                            <th>Commodity</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                            items.map((dat,index)=>(
                              <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{dat.enterprise}</td>
                                    <td>{dat.sellername}</td>
                                    <td>{dat.place}</td>
                                     <td>{dat.quantity}</td> 
                                    <td>{dat.commodity}</td>
                                    <td>{dat.date}</td>
                                    <td>{dat.amount}</td>
                                    <td>
                                    </td>
                              </tr>
          
                            ))
                          }
                        </tbody>
                      </table>
                      <div className=""><span style={{ fontStyle:'bold',fontSize:'20px', textAlign: 'right' ,marginRight:'120px',position:'absolute',right:'0' }}>Total amount: {totalamount}</span></div>
                    </div>
                </Container>  
                <Container sx={{marginTop:'100px'}}>
                   
                <div className="table">
                <h3>Pending Table</h3>
                    <table className='table table-striped'>
                        <thead>
                          <tr>
                             {/* <th>Serial Number</th> */}
                            <th>Enterprise</th>
                            <th>Seller Name</th>
                            <th>Place</th>
                             <th>Quantity</th> 
                            <th>Commodity</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
    pendingdata.map((dat,index)=>(
        // Wrap the condition inside a JSX expression
        dat.Enterprise===value && (
            <tr key={index}>
                {/* <td>{index+1}</td> */}
                <td>{dat.Enterprise}</td>
                <td>{dat.SellerName}</td>
                <td>{dat.place}</td>
                <td>{dat.Quantity}</td> 
                <td>{dat.Commodity}</td>
                <td>{dat.Date}</td>
                <td>{dat.Amount}</td>
                <td>
                    <div>
                        <Button variant='contained' color='success' sx={{ gridColumn: '1', justifySelf: 'center', ':hover': { backgroundColor: 'red' }  }} onClick={()=>pendingtoinvoice(index)} >Add</Button>
                    </div>
                </td>
                <td></td>
            </tr>
        )
    ))
                     }            
                      </tbody>
                    </table>
                    {pendingdata.length === 0 && <h2 style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'40px'}}>No pending bills</h2>}
                    </div>
                    </Container>  
            </Box>
            
        </Box>
        
    );
}


export default CreateInvoice;
