import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import { Box, Button, Container, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Usercreation = ({usernameforsidebar}) => {
  // STATES
  const [newuser, setNewuser] = useState({
    username: '',
    password: ''
  });
  const [role, setRole] = useState('');
  const[dataretrieve,setDataretrieve]=useState([])

  // FUNCTIONS
  const handleaddnewuser = (e) => {
    const { name, value } = e.target;
    setNewuser((prevUser) => ({ ...prevUser, [name]: value}));
  };
  const handleChange = (event) => {
    setRole(event.target.value);
};
// HANDLING POST METHOD
  const handlenewuser = () => {
    if (!newuser.username || !newuser.password) {
      alert("Enter User name and Password");
      return;
    }

    const data={...newuser,role:role}
     console.log(data)
    axios.post("http://localhost:3001/api/usercreation",data)
    .then((res)=>{
           console.log(res)
           alert("User Created Successfully")
           Fetchdata()
    })
    .catch((err)=>{
      console.log("there is an error",err)
    })
    
    setNewuser({
      username: '',
      password: ''
    });
    // Fetchdata()
    
  };

  //HANDLING DELTE METHOD
  const handledeleteuser=(username)=>{
    axios.delete(`http://localhost:3001/api/usercreation/${username}`)
    .then((res)=>{
        console.log(res)
        
        alert("user deleted sucessfully")
         Fetchdata()
    })
    .catch((err)=>{
       console.log("Error occured while deleting user",err)
    })
  }
      // HANDLING EDIT METHOD
      const handleEdituser=()=>{
        
      }
    const Fetchdata=()=>{
      axios.get("http://localhost:3001/api/usercreation")
      .then((res)=>{
           setDataretrieve(res.data)
            console.log(res.data)
  
      })
      .catch((err)=>{
          console.log("there is an error",err)
      })
    }
  useEffect(()=>{
     Fetchdata()
   },[])
    

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <SideBar usernameforsidebar={usernameforsidebar} />
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>

          <Container sx={{ display: 'flex',flexDirection:'column', width: '80rem', justifyContent: 'center', alignItems: 'center', gap: '40px', height: '25rem', cursor: 'pointer' }}>
                 <h1>User Management</h1>
            <TextField id="standard-basic" label="User Name" variant="standard" className='text-input' sx={{ fontWeight: 'bold', cursor: 'pointer' }} required name='username' value={newuser.username} onChange={handleaddnewuser} />
            <TextField id="standard-basic" label="Password" type='password' variant="standard" className='text-input' sx={{ fontWeight: 'bold', cursor: 'pointer' }} required name='password' value={newuser.password} onChange={handleaddnewuser} />
            <Select
                        value={role}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select' }}
                    >
                        <MenuItem value="" disabled>
                           Role
                        </MenuItem>
                        <MenuItem value={'Administrator'}>Administrator</MenuItem>
                        <MenuItem value={'Employee'}>Employee</MenuItem>
                    </Select>
            <Button variant='contained' color='success' size='small' sx={{ width: '160px', marginTop: '20px' }} onClick={handlenewuser}>Create User</Button>
          </Container>
          <Container sx={{display:'flex',justifyContent:'center',margin:'auto',width:'60%'}}>
            <div className="table">
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataretrieve.map((dat,index)=>(
                      <tr key={index}>
                          <td>{dat.UserName}</td>
                          <td>{dat.Role}</td>
                          <td>
                          <Button component={Link} to={`/edituser/${dat.ID}`} variant="contained" size="medium" color="primary" sx={{marginRight:'20px'}}>EDIT</Button> 
                          <  Button variant='contained' color='error' sx={{ gridColumn: '1',width:'50px', justifySelf: 'center', ':hover': { backgroundColor: 'goldenrod' }  }} onClick={()=>handledeleteuser(dat.UserName)} >Delete</Button></td> 
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Usercreation;
