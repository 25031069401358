import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../css files/invoicedetails.css';
import { FaSearchengin } from 'react-icons/fa6';
import SideBar from '../Components/Sidebar';

const Viewbill = ({usernameforsidebar}) => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [enterprise, setEnterprise] = useState(''); // State to store selected enterprise
    const [searchQuery, setSearchQuery] = useState(''); // State to store search query
    const [currentPage, setCurrentPage] = useState(1); // State to track current page
    const [itemsPerPage] = useState(15); // Number of items per page

    useEffect(() => {
        if (enterprise) {
            axios
                .get(`http://localhost:3001/api/bills?enterprise=${enterprise}`)
                .then((res) => {
                    const sortedData = res.data.map((item) => ({
                        ...item,
                        Date: new Date(item.Date),
                    })).sort((a, b) => b.Date - a.Date); // Sorting by descending date
                    setData(sortedData);
                    setFilteredData(sortedData); // Initialize filteredData with all data
                })
                .catch((err) => console.log('there is an error', err));
        }
    }, [enterprise]);

    useEffect(() => {
        // Filter data based on search query
        const filtered = data.filter((item) =>
            item.SellerName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);

    // Logic to get current items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleEnterpriseChange = (selectedEnterprise) => {
        setEnterprise(selectedEnterprise);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
      <Box sx={{ display: 'flex' }}>
        <SideBar usernameforsidebar={usernameforsidebar} />
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '55px' }}>
          <div className="d-flex flex-column align-items-center bg-light vh-100">
              <h1>List of Invoices</h1>
              <div className="w-100 rounded bg-white border shadow p-4">
                  <div className="d-flex justify-content-between mb-3">
                      <input 
                          type="text" 
                          placeholder="Search by Seller Name" 
                          value={searchQuery}
                          onChange={handleSearchChange} />
                      <select onChange={(e) => handleEnterpriseChange(e.target.value)}>
                          <option value="">Select Enterprise</option>
                          <option value="EnterpriseA">Enterprise A</option>
                          <option value="EnterpriseB">Enterprise B</option>
                          <option value="EnterpriseC">Enterprise C</option>
                      </select>
                      <Link to={'/createinvoice'} className="btn btn-success">Add Bill</Link>
                  </div>
                  <table className="table table-striped">
                      <thead>
                          <tr>
                              <th>Serial Number</th>
                              <th>Enterprise</th>
                              <th>Seller Name</th>
                              <th>Place</th>
                              <th>Quantity</th>
                              <th>Commodity</th>
                              <th>Amount</th>
                              <th>Date</th>
                          </tr>
                      </thead>
                      <tbody>
                          {currentItems.map((dat, index) => (
                              <tr key={index}>
                                  <td>{indexOfFirstItem + index + 1}</td>
                                  <td>{dat.Enterprise}</td>
                                  <td>{dat.SellerName}</td>
                                  <td>{dat.Place}</td>
                                  <td>{dat.Quantity}</td>
                                  <td>{dat.Commodity}</td>
                                  <td>{dat.Amount}</td>
                                  <td>{dat.Date.toDateString()}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
                  {/* Pagination */}
                  <ul className="pagination">
                      <li className={currentPage === 1 ? 'disabled' : ''}>
                          <button onClick={() => paginate(currentPage - 1)} className="page-link">Prev</button>
                      </li>
                      {[...Array(Math.ceil(filteredData.length / itemsPerPage)).keys()].map((number) => (
                          <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <button onClick={() => paginate(number + 1)} className="page-link">
                                  {number + 1}
                              </button>
                          </li>
                      ))}
                      <li className={currentPage === Math.ceil(filteredData.length / itemsPerPage) ? 'disabled' : ''}>
                          <button onClick={() => paginate(currentPage + 1)} className="page-link">Next</button>
                      </li>
                  </ul>
              </div>
          </div>
          </Box>
      </Box>
    );
};

export default Viewbill;
