import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from '../Components/Sidebar';
import { Button } from '@mui/material';

const Dashboard = ({usernameforsidebar}) => {
    // CUSTOMERS PAGINATION
    const [data, setData] = useState([]);
    const [currentpage, setCurrentpage] = useState(1);
    const recordsperpage = 8;
    const lastIndex = currentpage * recordsperpage;
    const firstIndex = lastIndex - recordsperpage;
    const content = data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(data.length / recordsperpage);
    const numbers = [...Array(npage).keys()].map((i) => i + 1);
     const history=useNavigate()
    // BILLS PAGINATION
    const [databill, setDatabill] = useState([]);
    const [currentpage1, setCurrentpage1] = useState(1);
    const recordsperpage1 = 8;
    const lastIndex1 = currentpage1 * recordsperpage1;
    const firstIndex1 = lastIndex1 - recordsperpage1;
    const content1 = databill.slice(firstIndex1, lastIndex1);
    const npage1 = Math.ceil(databill.length / recordsperpage1);
    const numbers1 = [...Array(npage1).keys()].map((i) => i + 1);

    useEffect(() => {
        axios.get('http://localhost:3001/api/customers')
            .then((res) => setData(res.data))
            .catch((err) => console.log("there is an error", err));

        axios.get('http://localhost:3001/api/bills')
            .then((res1) => setDatabill(res1.data))
            .catch((err1) => console.log("there is an error", err1));
    }, []);

    // Pagination Functions for Customers
    const prepage = () => {
        if (currentpage > 1) {
            setCurrentpage(currentpage - 1);
        }
    };

    const nextpage = () => {
        if (currentpage < npage) {
            setCurrentpage(currentpage + 1);
        }
    };

    const changeCpage = (page) => {
        setCurrentpage(page);
    };

    // Pagination Functions for Bills
    const prepage1 = () => {
        if (currentpage1 > 1) {
            setCurrentpage1(currentpage1 - 1);
        }
    };

    const nextpage1 = () => {
        if (currentpage1 < npage1) {
            setCurrentpage1(currentpage1 + 1);
        }
    };

    const changeCpage1 = (page) => {
        setCurrentpage1(page);
    };
    const handlelogout=()=>{
        history('/')
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <SideBar usernameforsidebar={usernameforsidebar} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px", display: 'flex', justifyContent: 'center' }}>
                {/* Customers Section */}
                <div className="d-flex flex-column align-items-center bg-light vh-100 mr-8">
                    <div className="">
                        <Button variant='contained' color='error' sx={{display:'flex',position:'absolute',right:'60px'}}   onClick={handlelogout}>Log Out</Button>
                    </div>
                    <h1>List of Customers</h1>
                    <div className="w-95 rounded bg-white border shadow p-3">
                        <div className="d-flex justify-content-between ">
                            <Link to={"/createcustomer"} className='btn btn-success '>Add customer</Link>
                            <Link to={"/createinvoice"} className='btn btn-primary '>Add Bill</Link>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Customer Name</th>
                                        <th>Mobile Number</th>
                                        <th>Town</th>
                                        <th>State</th>
                                        <th>Pincode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {content.map((dat, index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{dat.CustomerName}</td>
                                            <td>{dat.Mobilenumber}</td>
                                            {/* <td>{dat.Address}</td> */}
                                            <td>{dat.Town}</td>
                                            <td>{dat.State}</td>
                                            <td>{dat.Pincode}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* Customers Pagination */}
                            <nav>
                                <ul className='pagination'>
                                    <li className='page-item'>
                                        <button onClick={prepage} className='page-link'>Prev</button>
                                    </li>
                                    {numbers.map((n, i) => (
                                        <li className={`page-item${currentpage === n ? ' active' : ''}`} key={i}>
                                            <button onClick={() => changeCpage(n)} className='page-link'>{n}</button>
                                        </li>
                                    ))}
                                    <li className='page-item'>
                                        <button onClick={nextpage} className='page-link'>Next</button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                
            </Box>
        </Box>
    );
}

export default Dashboard;
