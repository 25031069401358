import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import SideBar from '../Components/Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Button } from '@mui/material';

const Customerbilldetails = ({usernameforsidebar}) => {
    //STATES
  const [data,setData]=useState([])
  const[pendingdata,setPendingdata]=useState([])
  const {CustomerID}=useParams()
  const history=useNavigate()

  //HOOKS
    useEffect(()=>{
      axios.get(`http://localhost:3001/api/customers/${CustomerID}`)
           .then((res)=>{setData(res.data)
          console.log(res.data)})
           .catch((err)=>console.log("there is an error",err))

           axios.get(`http://localhost:3001/api/pendinginvoices/${CustomerID}`)
           .then((res)=>{setPendingdata(res.data)
            console.log(res.data)})
             .catch((err)=>console.log("there is an error",err))
  
    },[])
    useEffect(()=>{
         console.log('pendingdata:',pendingdata)
    },[])
    const backnavigate=()=>{
        history('/viewcustomer')
    }
  return (
    <>
        <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
          <SideBar usernameforsidebar={usernameforsidebar} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 ,marginTop:"55px"}}>
               <h1>Details of Pending Bill Customer</h1>
               
                <div>
               <Button variant='contained' color='secondary' sx={{  justifySelf: 'center',position:'absolute',right:'100px' ,':hover': { backgroundColor: 'green' } }} onClick={backnavigate} >Back</Button>
               </div>
               <div className="table">
              
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Enterprise</th>
                                    <th>SellerName</th>
                                    {/* <th>Place</th> */}
                                    <th>Quantity</th>
                                    <th>Commodity</th>
                                    <th>Amount</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    
                                    pendingdata.map((dat, index) => (
                                        <tr key={index}>
                                            <td>{dat.Enterprise}</td>
                                            <td>{dat.SellerName}</td>
                                            {/* <td>{dat.Place}</td> */}
                                            <td>{dat.Quantity}</td>
                                            <td>{dat.Commodity}</td>
                                            <td>{dat.Amount}</td>
                                            <td>
                                            
                                            </td>
                                        </tr>
                                    ))
                                    
                                }
                            </tbody>
                        </table>
                        {pendingdata.length === 0 && <h2 style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'100px'}}>No pending bills</h2>}
               </div>
               
             
        </Box>
         
        </Box>
    </>
  )
}

export default Customerbilldetails