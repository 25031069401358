import React, { useEffect, useState } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import Abstract2 from '../images/Abstract2.jpg';
import '../css files/login.css'; // Import your custom CSS file

const LOGIN_URL = '/auth';

const Login = ({ setIsLoggedIn,setRole,setUsernameforsidebar }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [data,setData]=useState();
  const navigate = useNavigate();
  const image = Abstract2;
  
  const handleLogin = async (e) => { 
    e.preventDefault();
    try {
      const response = await axios.post(`https://a147-2409-408d-3197-f1da-89bf-b3e1-10bc-7c7c.ngrok-free.app/api/usercreation/${username}`);
      if (response.data) {
        const role = response.data[0].Role;
        const user = username;
        setIsLoggedIn(true);
        setRole(role);
        setUsernameforsidebar(user);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('role', role);
        localStorage.setItem('username', username);
        navigate('/dashboard');
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      console.error('Error occurred while logging in:', error);
      setError('Invalid username or password');
    }
  };
  
  
  return (
    <div className="login-container" style={{ backgroundImage: `url(${image})` }}>
      <Box
        className="login-box"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background for the login box
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
          Sign in
        </Typography>
        <form onSubmit={handleLogin} style={{ width: '100%', maxWidth: 400 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
            Sign In
          </Button>
          {error && <p style={{color:'red'}}>{error}</p>}
        </form>
      </Box>
    </div>
  );
};


export default Login;